//面板影藏事件
const ONHIDDEN="onHidden";
//下拉面板显示
const SHOW="show";
//单选的时候单击行
const CLICKTABLEROW="clickTableRow";
//comboGrid值改变事件（其实外界可以用watch来实现）
const COMBOGRIDCHANGE="comboGridChange";
//下拉框头部工具条编辑事件
const EDITHANDLER="editHandler";
//下拉框头部工具条刷新事件
const REFRESHHANDLER="refreshHandler";
//双向绑定用到的key
const updateKey='update:modelValue';
import {getCurrentInstance, reactive, toRefs,onBeforeMount, onMounted, defineComponent, watch, provide, nextTick} from 'vue';
import ComboGridUtil,{IComboGridDataObj} from "@/components/base_comp/comboGrid/comboGridUtil";

export default defineComponent({
    name: "comboGrid",
    props:{
        modelValue: {//表示该自定义控件的值，必须和上面updateKey里面的名称保持一样
            type: String
        },
        disabled:{type:Boolean,default:false},
        bindEvent:{type:Object,default:()=>{return{}}}
    },
    setup(props,context){
        const {proxy} = getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('gridTableOwner',proxy);
        let comboParam:any=context.attrs.params;
        let dataObj:IComboGridDataObj=reactive<IComboGridDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            visible:false,//popover的显示/影藏控制字段
            readOnly:false,//输入框是否只读（是否可以搜索）
            idField:"F_ID",//下拉框值字段名称，默认列F_ID
            txtField:"F_NAME",//下拉框显示字段名称，默认列F_NAME
            comboText: "",//下拉显示文本
            searchFieldsArray:[],//参与搜索的字段
            gridParam: {//下拉框中表格的参数
                showTitle:comboParam.showTitle?comboParam.showTitle:false,//默认不显示标题，除非传入参数明确要显示标题
                showToolBar:comboParam.showToolBar?comboParam.showToolBar:false,//默认不显示工具栏，除非传入参数明确要显示工具栏
                multiple:comboParam.multiple?comboParam.multiple:false,//是否多选
                canPage: comboParam.canPage?comboParam.canPage:true,
                autoLoad:false,//默认不自动加载grid，在展示面板的时候再去加载grid
                //表格查询条件，将要把这些参数传入后台
                queryParam: Object.assign({comboGrid: "comboGrid",searchText: ""}, comboParam),
                pagerParams:{//重写分页条
                    small:true,//让分页条变小一点
                    pagerCount:5,//默认是显示7个可操作页码，这里只能传入5到21之间的奇数
                    pageSize: 10,
                    layout:'prev, pager, next, jumper'//由于下拉框空间比较小，所以分页显示的东西变少了
                },
                modelMethod:comboParam.modelMethod//下拉的请求路径
            },
            comboParam:comboParam,
            otherParams:{
                popoverWidth:400
            }
        });
        onBeforeMount(()=>{
            dataObj.utilInst=new ComboGridUtil(proxy,dataObj,props,context);
        })
        onMounted(async()=>{
            await nextTick(async ()=>{
                await dataObj.utilInst.init(comboParam,props.modelValue);//根据传入的参数进行默认参数覆盖
                if(!comboParam.maxHeight)comboParam.maxHeight=200;//如果没有传入表格最大高度，则默认为200
                getTbInst().setMaxTbHeight(comboParam.maxHeight);
                let inputGroup:any=document.querySelector('.el-input-group');
                if(inputGroup && inputGroup.clientWidth && inputGroup.clientWidth>200){
                    dataObj.otherParams.popoverWidth=inputGroup.clientWidth;
                }
            })
        });

        //监控下拉框值改变
        watch(() => props.modelValue,async (newValue,oldValue) => {
            await dataObj.utilInst.comboGridValueChange(newValue,oldValue,props.modelValue,comboParam,context);
            await dataObj.utilInst.emitEvent(COMBOGRIDCHANGE,newValue,oldValue)//触发事件
        })

        //如果传入的参数发生了改变，需要重新初始化参数，否则会有问题，比如代码生成那里，选择了子表名称之后，
        // 点击主表关联字段，下拉框在show的时候，发现tbName始终为空，然而明明外层用computed已经改变了tbName，
        // 但是在combogrid的gridparam.queryparam中，接收到外面传过来的tbName始终是空，仿佛外界即便用computed
        // 向combogrid传参不会自动更新dataObj.gridParam.queryParam，所以下面就手动去更新一下该参数
        // watch(() => comboParam,async (newValue,oldValue) => {
        watch(() => context.attrs.params,async (newValue,oldValue) => {
            await dataObj.utilInst.init(newValue,props.modelValue);
            dataObj.gridParam.queryParam=Object.assign({comboGrid: "comboGrid",searchText: ""}, comboParam);
        })

        //根据下拉框的值找出该值的显示文本
        const getTextById=async()=>{
            await dataObj.utilInst.getTextById(props.modelValue,comboParam);
        }
        //下拉框搜索事件
        const inputChange=(value:string)=>{
            if (value == '')context.emit(updateKey,'');//如果清空了下拉框，则触发事件改变下拉框的值
            if(comboParam.content){//静态数据下拉框
                let tempContent=comboParam.content.slice(0);//深拷贝一份静态数据，否则会影响原数组
                comboParam.content.forEach((row:any, index:number)=> {
                    //查询所有参与搜索的字段是否包含输入的搜索值
                    let item=dataObj.searchFieldsArray.find((field:string) => row[field].indexOf(value)>-1);
                    //不包含搜索值，则去掉该项
                    if(!item)tempContent.splice(index,1);
                });
                getTbInst().setTbData(tempContent);
            }else{//带上搜索的内容到后台取查询，重新加载表格
                dataObj.gridParam.queryParam.searchText = value;
                getTbInst().queryHandler(true);
            }
        }

        //点击行事件。注意：在gridTable那里会调用toggleRowSelection方法，那么就会触发表格的select-change事件，
        //所以下方的selectionChange方法会被调用；
        //但是如果仅仅点击复选框，只会触发selection-change事件，不会触发点击行事件，就不会调到这个方法
        const rowClickHandler=async(row:any)=>{
            await dataObj.utilInst.emitEvent(CLICKTABLEROW,row)//触发事件
            if (!comboParam.multiple) {//不是多选的情况，点击某行之后，就关闭下拉框
                dataObj.refMap.get('input').$el.click();//手动触发点击输入框事件，关闭popover，因为直接在这里改变visible没有卵用
            }
        }

        //这里只处理选上的情况，取消选择交给下方三个方法去处理
        //点击单选、全选、翻页、点击行都会自动调用该方法,翻页的时候selection长度为0
        const selectionChange=(selection:any)=>{
            if (selection.length>0){
                let _comboText = "", _comboValue = "";
                selection.forEach((row:any)=>{
                    //当modelValue为空或者modelValue不包含勾选行的时候，才处理添加
                    if(!props.modelValue || props.modelValue.indexOf(row[dataObj.idField])==-1){
                        _comboValue = _comboValue + row[dataObj.idField] + ",";
                        _comboText = _comboText + row[dataObj.txtField]+ ",";
                    }
                });
                //去掉最后的逗号（由于上方的判断，所以可能_comboValue、_comboText为空）
                if(_comboValue)_comboValue = _comboValue.trim().substr(0, _comboValue.trim().length - 1);
                if(_comboText)_comboText = _comboText.trim().substr(0, _comboText.trim().length - 1);

                if(dataObj.comboText && _comboText){
                    if(comboParam.multiple) dataObj.comboText=dataObj.comboText+','+_comboText;//如果有值，再勾选其它的，则拼接
                    else dataObj.comboText=_comboText;
                }else if(!dataObj.comboText && _comboText)dataObj.comboText = _comboText;//如果以前没值，现在有值，则把现在的赋值给它

                if(props.modelValue && _comboValue){
                    if(comboParam.multiple) _comboValue=props.modelValue+','+_comboValue;//如果之前有值，再勾选其它的，则拼接
                    else  _comboValue=_comboValue;
                }else if(props.modelValue && !_comboValue)_comboValue=props.modelValue;//如果之前有值，本次没有改变，则把之前的赋值给_comboValue，下方好判断更不更新
                if(_comboValue!=props.modelValue)context.emit(updateKey,_comboValue);//触发事件，改变下拉框的值
            }
        }
        //点击复选框的时候才会调用该事件方法(全选、取消全选都不会调用这里)
        //selection-当前表格勾选的行，row-当前勾选或取消勾选的行
        //这里处理复选框取消选择的情况
        const select=(selection:any, row:any)=>{
            let result:boolean=selection.some((item:any)=>item[dataObj.idField]==row[dataObj.idField]);
            if(!result)dataObj.utilInst.dealCancel(row,updateKey);//只处理取消选择的情况
        }
        //点击行才会调用该事件方法，这里只处理点击行取消选择的情况
        const rowClickStatusChange=(row:any,isSelect:boolean)=>{
            if(comboParam.multiple && !isSelect){
                dataObj.utilInst.dealCancel(row,updateKey);
            }
        }
        //全选、取消全选的时候才会调用该事件方法（一个一个勾选，造成的全选取消全选也不会调用）
        //这里只处理取消全选的情况
        const selectAll=(selection:any)=>{
            if(selection.length==0){//只处理取消全选的情况，全选的情况在selectionChange那里处理过了
                dataObj.comboText = "";
                context.emit(updateKey,'');
            }
        }

        //下拉面板影藏事件
        const hide=async ()=>{
            let tbData = getTbInst().getTbData();
            //当下拉面板影藏，搜索文本有值的时候，表示有搜索到记录，那么就取表格第一条记录
            //(其实以下的判断没有用，万一后台没有做搜索，会存在不管你输入什么搜索，前端都有记录，这个时候下方的判断就有意义了)
            if (tbData.length != 0  && dataObj.gridParam.queryParam.searchText) {
                let flag:boolean=false;
                for(let i=0;i<dataObj.searchFieldsArray.length;i++){
                    if(tbData[0][dataObj.searchFieldsArray[i]].indexOf(dataObj.gridParam.queryParam.searchText)>-1){
                        dataObj.comboText = tbData[0][dataObj.txtField];
                        context.emit(updateKey,tbData[0][dataObj.idField]);//触发事件，改变下拉框的值
                        flag=true;
                        break;
                    }
                }
                if(!flag){
                    dataObj.comboText = '';
                    context.emit(updateKey,'');//触发事件，改变下拉框的值
                }
            } else if (tbData.length == 0) {//影藏面板，表格没有值，不管是否在搜索，下拉框都不应该有值
                dataObj.comboText = '';
                context.emit(updateKey,'');//触发事件，改变下拉框的值
            }
            await dataObj.utilInst.emitEvent(ONHIDDEN,{comboValue:props.modelValue})//触发事件
            dataObj.gridParam.queryParam.searchText = "";//关闭面板，清空搜索框的值
        }
        //下拉面板显示事件
        const show=async()=>{
            // console.log(dataObj.refMap.get('gridTable').$el.getBoundingClientRect().top)//获取表格距离窗口顶部的距离
            if(comboParam.content){//静态数据下拉框，直接赋值给下拉框
                getTbInst().setTbData(comboParam.content);
            }else{//不是静态表格，则发送请求去获取数据
                //如果表格没有数据，那么可能是还没有去加载，那么就发送一次请求加载表格数据
                if(getTbInst().getTbData().length==0)await getTbInst().queryHandler();
                //如果下拉框有值，则把下拉框中对应行给选择上，只会处理第一页，如果值在第二页，那么不会跳转到第二页把对应行给选择上，不会
                if(props.modelValue)selectByComboValue();
            }
            await dataObj.utilInst.emitEvent(SHOW)//触发事件
        }
        //根据combovalue选中对应行，如果表格是分页的，而当前页没有找到对应的comboValue，则不选择表格任何行
        const selectByComboValue=()=>{
            let tbRef=getTbInst().tbRef;
            let tbData = getTbInst().getTbData();
            tbRef.clearSelection();//清空表格选中项
            tbData.forEach((row:any)=> {
                if (comboParam.multiple) {//多选，有下拉框，则把下拉框给选中
                    if ((props.modelValue as string).indexOf(row[dataObj.idField]) > -1) tbRef.toggleRowSelection(row,true);
                } else {
                    if (row[dataObj.idField] == props.modelValue) tbRef.setCurrentRow(row);//没有复选框选中行变色
                }
            });
        }
        //供业务层重新传参查询下拉表格
        const setQueryParams=(appendParams:any)=>{
            Object.assign(dataObj.gridParam.queryParam, appendParams);
            getTbInst().queryHandler(true);
            dataObj.comboText = "";
        }

        //清空输入框事件，其实就是把下拉框选择的值清空
        const inputClear=async ()=>{
            dataObj.comboText = "";
            context.emit(updateKey,'');
            dataObj.gridParam.queryParam.searchText = "";
            await refreshHandler();
        }
        //----------------------------toolbar--------------------------
        //下拉框编辑按钮事件（没有实现任何内容，交给业务模块去实现）
        const editHandler=async ()=>{
            await dataObj.utilInst.emitEvent(EDITHANDLER)//触发事件
        }
        //刷新下拉框，重新加载下拉表格
        const refreshHandler=async()=>{
            await getTbInst().queryHandler(true);
            let tbData = getTbInst().getTbData();
            await dataObj.utilInst.emitEvent(REFRESHHANDLER,{tbData:tbData})//触发事件
        }
        //--------------------------------------------------------------
        //得到下拉框中表格的引用
        const getTbInst=()=>{
            return dataObj.refMap.get('gridTable');
        }
        //表格加载完毕
        const gridLoaded=(res:any)=>{
            if(props.modelValue){
                let tbRef=getTbInst().tbRef;
                res.rows.forEach((item:any)=>{
                    if((props.modelValue as string).indexOf(item[dataObj.idField])>-1){
                        nextTick(()=>{//要放到nextTick里面，否则不能勾选成功。可能gridLoaded在的时候，表格数据还没有完全渲染成功
                            tbRef.toggleRowSelection(item,true);
                        })
                    }
                })
            }
        }
        return{
            ...toRefs(dataObj),getTextById,inputChange,rowClickHandler,selectionChange,select,rowClickStatusChange,selectAll,
            hide,show,setQueryParams,selectByComboValue,inputClear,editHandler,
            refreshHandler,getTbInst,gridLoaded
        }
    },
    components: {}
});